import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import ButtonLink from '../ButtonLink/ButtonLink'
import { Header2 } from '../Headers/Headers'

const StyledMonumentCard = styled.div`
  position: relative;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 450px auto;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const StyledImg = styled(Img)`
  border-radius: 5px;
`

const MonumentCard = ({ children, linkTo, fluid, title }) => (
  <StyledMonumentCard>
    <StyledImg fluid={fluid} />
    <div>
      <Header2>{title}</Header2>
      <p>{children}</p>
      <ButtonLink linkTo={linkTo}>Czytaj więcej</ButtonLink>
    </div>
  </StyledMonumentCard>
)

export default MonumentCard
