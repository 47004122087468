import React from 'react'
import styled from 'styled-components'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'

const ReturnWrapper = styled(AnchorLink)`
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  width: max-content;
  cursor: pointer !important;
  transform: translateY(${({ transform }) => (transform ? '30px' : '0')});
`

const ReturnToPrevPage = ({ to, children, transform }) => (
  <ReturnWrapper transform={transform} to={to}>
    <NavigateBeforeIcon style={{ fontSize: 22 }} />
    {children ? children : `Powrót do poprzedniej strony`}
  </ReturnWrapper>
)

export default ReturnToPrevPage
