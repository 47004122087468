import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardPomnikNiepodleglosciCeglow = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/ceglow/pomnik-niepodleglosci-ceglow/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/ceglow/pomnik-niepodleglosci-w-ceglowie'}
      title={'Pomnik Niepodległości w Cegłowie'}
    >
      Na historycznym Rynku, obecnym placu Anny Jagiellonki, usytuowano Pomnik
      upamiętniający dziesiątą rocznicę odzyskania Niepodległości. Jego
      uroczyste odsłonięcie nastąpiło jednak dopiero 10 maja 1934 roku.
      Inicjatorami budowy byli wójt Kieliszczyk, sekretarz gminy Chodorowski,
      ksiądz proboszcz Fijałkowski oraz dyrektor szkoły Nielepiec. Pomnik
      powstał dzięki zbiórkom wśród lokalnej społeczności oraz wsparciu urzędu
      gminy, zaś wieńczący go orzeł z brązu został ufundowany przez rodzinę
      Zawadzkich ze Skwarnego, aktywnie działającą wówczas w Akcji Katolickiej.
      Dziś cegłowski Pomnik Niepodległości stanowi główne miejsce gminnych
      obchodów świąt państwowych.
    </MonumentCard>
  )
}

export default MonumentCardPomnikNiepodleglosciCeglow
