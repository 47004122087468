import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardCeglowKosciol = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/ceglow/kosciol-w-ceglowie/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/ceglow/kosciol-rzymsko-katolicki-w-ceglowie'}
      title={'Kościół Rzymsko-Katolicki w Cegłowie'}
    >
      Parafia w Cegłowie swoją historię ma krótszą o zaledwie rok od tej w
      Mińsku Mazowieckim i poszczycić się może zabytkami bardzo wysokiej rangi,
      o znaczeniu ogólnopolskim. Najważniejszym z nich jest ołtarz autorstwa
      Lazarusa, który uchodzi za jedno z najwybitniejszy dzieł rzeźby
      późnogotyckich. Datowana na 1510 rok od 1629 roku jest ozdobą ołtarza.
    </MonumentCard>
  )
}

export default MonumentCardCeglowKosciol
