import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import ButtonLinkAsset from '../ButtonLink/ButtonLinkAsset'
import { Header2 } from '../Headers/Headers'
import { PictureAsPdf, Headset } from '@material-ui/icons'
import ReactAudioPlayer from 'react-audio-player'

const StyledMonumentCard = styled.div`
  position: relative;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 450px auto;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;

  h3 {
    color: var(--primaryDark);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const TitleContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  .icons {
    font-size: 23px;
    margin-left: 10px;
    transform: translateY(3px);
  }
`

const MonumentCardAsset = ({
  children,
  linkTo,
  title,
  author,
  authors,
  assetName,
  pdfIcon,
  audioIcon,
  buttonText,
  audioURL,
}) => {
  return (
    <StyledMonumentCard>
      <TitleContainer>
        <Header2 style={{ marginBottom: 0, marginRight: 10 }}>
          {assetName}
          {pdfIcon && <PictureAsPdf className={'icons'} />}
          {audioIcon && <Headset className={'icons'} />}
        </Header2>
      </TitleContainer>
      <div>
        <Header2>{title}</Header2>
        {author && <h3>Autor: {author}</h3>}
        {authors && <h3>Autorzy: {authors}</h3>}
        {children && <p>{children}</p>}
        {linkTo && (
          <ButtonLinkAsset linkTo={linkTo}>
            {buttonText ? buttonText : 'Czytaj więcej'}
          </ButtonLinkAsset>
        )}
        {audioURL && <ReactAudioPlayer src={audioURL} controls />}
      </div>
    </StyledMonumentCard>
  )
}

export default MonumentCardAsset
