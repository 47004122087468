import React from 'react'
import styled from 'styled-components'
import ReturnToPrevPage from '../ReturnToPrevPage/ReturnToPrevPage'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const StyledArchiveCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
`

const ArchiveCardWrapper = ({ children }) => (
  <StyledArchiveCardWrapper>
    <ReturnToPrevPage to={'/'} transform={true}>
      Strona główna
    </ReturnToPrevPage>
    {children}
  </StyledArchiveCardWrapper>
)

export default ArchiveCardWrapper
