import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { withPrefix } from 'gatsby'

const StyledButtonLink = styled.a`
  display: block;
  font-weight: 700;
  color: white;
  background-color: var(--primaryDark);
  padding: 10px 30px;
  text-align: center;
  border-radius: 6px;
  width: max-content;
  margin-left: auto;

  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
`

const ButtonLinkAsset = ({ linkTo, children }) => (
  <StyledButtonLink href={linkTo} target="_blank" download={linkTo}>
    {children}
  </StyledButtonLink>
)

export default ButtonLinkAsset
