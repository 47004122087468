import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const StyledButtonLink = styled(Link)`
  display: block;
  font-weight: 700;
  color: white;
  background-color: var(--primaryDark);
  padding: 10px 30px;
  text-align: center;
  border-radius: 6px;
  width: max-content;
  margin-left: auto;
  margin-right: ${({ center }) => (center ? 'auto' : 'unset')};

  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
`

const ButtonLink = ({ linkTo, children, ...props }) => (
  <StyledButtonLink to={linkTo} {...props}>
    {children}
  </StyledButtonLink>
)

export default ButtonLink
