import { Container } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const StyledArchiveWrapper = styled(Container)`
  margin-top: 50px;
  margin-bottom: 50px;

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const ArchiveWrapper = ({ children }) => (
  <StyledArchiveWrapper>{children}</StyledArchiveWrapper>
)

export default ArchiveWrapper
