import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardKiczkiKosciolMuzeum from '../../../monuments/ceglow/MonumentCardKiczkiKosciolMuzeum'
import MonumentCardPomnikNiepodleglosciCeglow from '../../../monuments/ceglow/MonumentCardPomnikNiepodleglosciCeglow'
import MonumentCardCeglowKosciol from '../../../monuments/ceglow/MonumentCardCeglowKosciol'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'

const CeglowArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Cegłów" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Cegłów</Header1>
        <MonumentCardKiczkiKosciolMuzeum />
        <MonumentCardPomnikNiepodleglosciCeglow />
        <MonumentCardCeglowKosciol />
        <MonumentCardAsset
          title={'Sacrum Terrae Cegloviensis'}
          assetName={'MATERIAŁ'}
          pdfIcon
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/ceglow/Sacrum+Terrae+Cegloviensis.pdf'
          }
          buttonText={'Otwórz'}
        />
        <MonumentCardAsset
          title={'Cegłów dawniej i dziś'}
          assetName={'MATERIAŁ'}
          pdfIcon
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/ceglow/Album_Ceglow_dawniej_i_dzis_04.pdf'
          }
          buttonText={'Otwórz'}
        />

        <MonumentCardAsset
          title={'Gmina Cegłów - przewodnik encyklopedyczny'}
          assetName={'MATERIAŁ'}
          pdfIcon
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/ceglow/Encyklopedia_Ceglow_srodek_01.pdf'
          }
          buttonText={'Otwórz'}
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default CeglowArchive
