import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardKiczkiKosciolMuzeum = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/ceglow/kiczki-kosciol-i-muzeum-parafialne/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/ceglow/kiczki-kosciol-i-muzeum-parafialne'}
      title={'Kiczki - Kościół i Muzeum Parafialne'}
    >
      Z dwóch odrębnych części składa się druga co do wielkości miejscowość
      znajdująca się w gminie Cegłów. W obu, Kiczkach Pierwszych i Kiczkach
      Drugich, można obejrzeć ciekawe, z punktu widzenia krajoznawczego,
      obiekty. Odwiedziny w pierwszej z wsi przyniosą możliwość podziwiania
      ponad stuletnich dworu otoczonego parkowymi drzewami oraz młyna wodnego.
      Nieco młodszy jest budynek kuźni, który pochodzi z lat dwudziestych
      ubiegłego stulecia. W drugiej z miejscowości zabytkiem jest
      osiemnastowieczny kościół parafialny pod wezwaniem świętej Anny. Stanowi
      on przykład sakralnej architektury drewnianej.
    </MonumentCard>
  )
}

export default MonumentCardKiczkiKosciolMuzeum
