import React from 'react'
import styled from 'styled-components'

const HeaderWrapper = styled.div`
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const StyledHeader1 = styled.h1`
  display: inline;
  color: var(--primaryDark);
  margin-top: 0;
`

const StyledHeader2 = styled.h2`
  margin: 0;
  display: inline;
  color: var(--primaryDark);
`

const Block1 = styled.aside`
  display: inline-block;
  background-color: var(--primary);
  height: 23px;
  width: 16px;
  margin-right: 10px;
`

const Block2 = styled.aside`
  display: inline-block;
  background-color: var(--primary);
  height: 18px;
  width: 12px;
  margin-right: 10px;
`

const Header1 = ({ children }) => (
  <HeaderWrapper>
    <Block1 />
    <StyledHeader1>{children}</StyledHeader1>
  </HeaderWrapper>
)

const Header2 = ({ children }) => (
  <HeaderWrapper style={{ marginTop: 0 }}>
    <Block2 />
    <StyledHeader2>{children}</StyledHeader2>
  </HeaderWrapper>
)

export { Header1, Header2 }
